import { combineReducers } from "redux";
import dataSlice from "./data/data.slice";
import userSlice from "./user/user.slice";
import categorySlice from "./category/category.slice";
import brandSlice from "./brand/brand.slice";
import staffSlice from "./staff/staff.slice";
import attributeSlice from "./attribute/attribute.slice";
import colorSlice from "./color/color.slice";
import productSlice from "./product/product.slice";
import usersSlice from "./users/users.slice";
import blogSlice from "./blog/blog.slice";
import { themeReducer } from "./theme/theme.reducer";

export const rootReducer = combineReducers({
  data: dataSlice.reducer,
  user: userSlice.reducer,
  category: categorySlice.reducer,
  brand: brandSlice.reducer,
  staff: staffSlice.reducer,
  attribute: attributeSlice.reducer,
  color: colorSlice.reducer,
  products: productSlice.reducer,
  users: usersSlice.reducer,
  blog: blogSlice.reducer,
  theme: themeReducer,
});
