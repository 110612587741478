// import { Spinner } from "@nextui-org/react";
import BouncingBallSvg from "assets/svgs/animated/bouncing-ball";
const Preloader = () => {
  return (
    <div className="fixed z-[1010] centered inset-0 bg-white dark:bg-primary">
      <BouncingBallSvg className="w-16 h-16 lg:w-24 lg:h-24" />
    </div>
  );
};

export default Preloader;
