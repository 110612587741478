"use client";

import { useEffect, useRef, useState } from "react";
import Reveal from "../toolkit/reveal.component";
import { AnimateType, classNames, goToTop } from "utils/helper/helper";

function ProductGoToTop({
  children,
  className,
}: {
  children: React.ReactElement;
  className: string;
}) {
  const [scrolled, setScrolled] = useState<boolean>(false);
  const mainContentRef = useRef<HTMLDivElement | null>(null);
  const goToTop = () => {
    mainContentRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (mainContentRef.current && mainContentRef.current.scrollTop >= 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const mainContent = mainContentRef.current;
    mainContent?.addEventListener("scroll", handleScroll);

    return () => {
      mainContent?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={className}
      ref={mainContentRef}>
      {scrolled && (
        <Reveal
          type={AnimateType.FadeInUp}
          className="fixed z-[9999999999] right-6 bottom-[124px] md:bottom-[180px] gizmo:md:bottom-[143px] lg:bottom-[80px]">
          <button
            onClick={goToTop}
            aria-label="Go to top"
            className={classNames(
              "cursor-pointer bg-primary group ml-2 flex h-10 w-10 items-center justify-center",
              "rounded-full border border-gray-100 transition-colors hover:border-transparent",
              "hover:bg-primary focus:border-transparent focus:bg-primary",
              "dark:border-transparent dark:bg-white/[.15] dark:hover:bg-primary text-primary-50"
            )}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="icon-sm m-1"
              height="1em"
              width="1em">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
              />
            </svg>
          </button>
        </Reveal>
      )}
      {children}
    </div>
  );
}

export default ProductGoToTop;
