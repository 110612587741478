import { getBlogBySlug } from "actions/blog";
import { LoaderFunctionArgs } from "react-router-dom";

export const blogLoader = async ({ params }: LoaderFunctionArgs) => {
  const { slug } = params;
  if (slug) {
    const blog = await getBlogBySlug(slug);
    return blog;
  }
  return null;
};
