import { alertMessage } from "components/toolkit/initial-state.component";

import { getHeaders } from "utils/header";
import { getEnvProps } from "utils/helper/server-helper";
import { Brand } from "utils/types/brand";

type BrandFormData = {
  logo: FormDataEntryValue | null;
  name: FormDataEntryValue | null;
};

export type BrandValidationErrors = {
  logo?: string;
  name?: string;
};

const validateForm = (Brand: BrandFormData): BrandValidationErrors => {
  const errors: BrandValidationErrors = {};

  if (!Brand.name) {
    errors.name = "Please name is required";
  }
  if (!Brand.logo) {
    errors.logo = "Please logo is required";
  }
  return errors;
};

const isFormValidated = (brand: BrandFormData) => {
  const errors = {
    name: !brand.name,
    logo: !brand.logo,
  };
  return Object.values(errors).every((error) => !error);
};

export const addBrand = async (prevState: any, formData: FormData) => {
  const envProps = await getEnvProps();
  const hostURL = envProps.base_url;

  const brand = {
    logo: formData.get("logo"),
    name: formData.get("name"),
  };

  if (!isFormValidated(brand)) {
    return {
      message: "Invalid inputs",
      errors: validateForm(brand),
      success: false,
    };
  }

  try {
    const uri = `${hostURL}/api/v1/admin/brands`;
    const headers = await getHeaders(undefined, true);
    const req = await fetch(uri, {
      method: "POST",
      headers,
      body: formData,
    });

    if (!req.ok) {
      const res = await req.json();
      throw new Error(res.message || "Failed to add Brand");
    }

    const res = await req.json();

    alertMessage("success", "Brand Uploaded Successfully");
    return {
      message: "Brand Uploaded Successfully",
      success: true,
      data: res.data,
    };
  } catch (error) {
    if (error instanceof Error) {
      return {
        message: error.message,
        success: false,
        data: null,
      };
    } else {
      return {
        message: "An unexpected error occurred",
        success: false,
        data: null,
      };
    }
  }
};
export const editBrand = async (prevState: any, formData: FormData) => {
  const brand = {
    logo: formData.get("logo"),
    name: formData.get("name"),
  };

  if (!isFormValidated(brand)) {
    return {
      message: "Invalid inputs",
      errors: validateForm(brand),
      success: false,
    };
  }
  const uri = String(formData.get("uri"));

  try {
    const headers = await getHeaders(undefined); // delete headers["Content-Type"]; // Let the browser set this automatically// Get the form element

    // Create an empty object to hold the key/value pairs
    const jsonObject: { [key: string]: any } = {};

    // Iterate over each key/value pair in the FormData object
    formData.forEach((value, key) => {
      // Check if the key already exists in the jsonObject
      if (jsonObject.hasOwnProperty(key)) {
        // If it's an array, append the new value
        if (Array.isArray(jsonObject[key])) {
          jsonObject[key].push(value);
        } else {
          // If it's not an array, convert it to an array and append the new value
          jsonObject[key] = [jsonObject[key], value];
        }
      } else {
        // If the key doesn't exist, simply assign the value
        jsonObject[key] = value;
      }
    });

    // Convert the JavaScript object to a JSON string
    const jsonString = JSON.stringify(jsonObject);
    const req = await fetch(uri, {
      method: "PUT",
      headers,
      body: jsonString,
    });

    if (!req.ok) {
      const res = await req.json();
      throw new Error(res.message || "Failed to add Brand");
    }

    const res = await req.json();

    alertMessage("success", "Brand Uploaded Successfully");
    return {
      message: "Brand Uploaded Successfully",
      success: true,
      data: res.data,
    };
  } catch (error) {
    if (error instanceof Error) {
      return {
        message: error.message,
        success: false,
        data: null,
      };
    } else {
      return {
        message: "An unexpected error occurred",
        success: false,
        data: null,
      };
    }
  }
};

export const getBrandBySlug = async (slug: string): Promise<Brand | null> => {
  const envProps = await getEnvProps();
  const headers = await getHeaders(undefined, true);

  let brand: Brand | null = null;

  try {
    const req = await fetch(
        `${envProps.base_url}/api/v1/admin/get-brand/${slug}`,
        {
          headers,
        }
      ),
      res = await req.json();

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, Brand not found.");
    }
  } catch (error) {
    console.error("Error fetching brand:", error);
    // throw new Error("Error fetching brand");
  }

  return brand;
};
