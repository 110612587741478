import { getEnvProps } from "utils/helper/server-helper";
import { getHeaders } from "utils/header";
import validator from "validator";

export async function getUserByID<T>(id: string): Promise<T | null> {
  const envProps = await getEnvProps();
  const headers = await getHeaders(undefined, true);

  let user: T | null = null;

  try {
    const req = await fetch(`${envProps.base_url}/api/v1/admin/users/${id}`, {
        headers,
      }),
      res = await req.json();

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, User not found.");
    }
  } catch (error) {
    console.error("Error fetching User:", error);
    // throw new Error("Error fetching User");
  }

  return user;
}

export const validateUserLogin = (data: { [key: string]: string }) => {
  const validationErrors: { [key: string]: string } = {};

  if (data["email"] === "") {
    validationErrors.email = "Please email is required";
  }
  if (data["email"] && !validator.isEmail(data["email"].toString())) {
    validationErrors.email = "This must be of type 'email'";
  }
  if (!data["password"] || data["password"].length < 1) {
    validationErrors.password = "Password is required";
  }
  return validationErrors;
};
