import { defaultProductData } from "utils/helper/states";
import { Product, ProductItem } from "utils/types/products";
import { createSlice } from "@reduxjs/toolkit";
import { productReducers } from "./actions";
import { Attribute } from "utils/types/attribute";

export type ProductState = {
  all: ProductItem[] | null;
  searchValue: string;
  start_date: string | null;
  end_date: string | null;
  loading: boolean;
  product: Product;
  selectedAttributes: Attribute[] | null;
  sortOption: string;
  limit: number;
  selectedKeys: number[];
};

const INITIAL_STATE: ProductState = {
  all: null,
  product: defaultProductData,
  searchValue: "",
  start_date: null,
  end_date: null,
  loading: false,
  selectedAttributes: null,
  sortOption: "date_desc",
  limit: 10,
  selectedKeys: [],
};
const productSlice = createSlice({
  name: "products",
  initialState: INITIAL_STATE,
  reducers: productReducers,
});

export const productActions = productSlice.actions;
export default productSlice;
