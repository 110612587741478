import { all, call } from "typed-redux-saga";
import { dataSagas } from "./data/data.saga";
import { userSagas } from "./user/user.saga";
import { categorySagas } from "./category/category.saga";
import { brandSagas } from "./brand/brand.saga";
import { staffSagas } from "./staff/staff.saga";
import { attributeSagas } from "./attribute/attribute.saga";
import { colorSagas } from "./color/color.saga";
import { productSagas } from "./product/product.saga";
import { usersSagas } from "./users/users.saga";
import { blogSagas } from "./blog/blog.saga";

export function* rootSaga() {
  yield* all([
    call(dataSagas),
    call(userSagas),
    call(categorySagas),
    call(brandSagas),
    call(staffSagas),
    call(attributeSagas),
    call(colorSagas),
    call(productSagas),
    call(usersSagas),
    call(blogSagas),
  ]);
}
