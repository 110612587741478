import { Button } from "@nextui-org/react";
import { CiBellOn } from "react-icons/ci";

function HeaderNotification() {
  return (
    <Button
      isIconOnly
      variant="light"
      className="relative">
      <CiBellOn size={25} />
      <span className="absolute top-1.5 right-1.5 flex w-1.5 h-1.5">
        <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-400"></span>
        <span className="relative inline-flex w-1.5 h-1.5 rounded-full bg-sky-500"></span>
      </span>
    </Button>
  );
}

export default HeaderNotification;
