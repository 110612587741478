import { getAttributeByID } from "actions/attribute";
import { getBrandBySlug } from "actions/brands";
import { getCategoryBySlug } from "actions/categories";
import { getProductBySlug } from "actions/products";
import { LoaderFunctionArgs } from "react-router-dom";

export const attributeLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (id) {
    const data = await getAttributeByID(id);
    return data;
  }
  return null;
};

export const brandLoader = async ({ params }: LoaderFunctionArgs) => {
  const { slug } = params;
  if (slug) {
    const data = await getBrandBySlug(slug);
    return data;
  }
  return null;
};
export const categoryLoader = async ({ params }: LoaderFunctionArgs) => {
  const { slug } = params;
  if (slug) {
    const data = await getCategoryBySlug(slug);
    return data;
  }
  return null;
};
export const productLoader = async ({ params }: LoaderFunctionArgs) => {
  const { slug } = params;
  if (slug) {
    const data = await getProductBySlug(slug);
    return data;
  }
  return null;
};
