import { createSelector } from "reselect";
import { RootState } from "../store";

const selectDataReducer = (state: RootState) => state.data;

export const selectSiteSettings = createSelector(
  [selectDataReducer],
  (data) => data.site_settings
);
export const selectAppSettings = createSelector(
  [selectDataReducer],
  (data) => data.app_settings
);
export const selectOverviewOption = createSelector(
  [selectDataReducer],
  (data) => data.overviewFilter
);
export const selectOverview = createSelector(
  [selectDataReducer],
  (data) => data.overview
);
export const selectSearchHistory = createSelector(
  [selectDataReducer],
  (data) => data.search_history
);
export const selectSidebarSm = createSelector(
  [selectDataReducer],
  (data) => data.isSidebarSm
);
export const selectCurrencies = createSelector(
  [selectDataReducer],
  (data) => data.currencies
);

export const selectCountries = createSelector(
  [selectDataReducer],
  (data) => data.countries
);
