import DashboardSvg from "assets/svgs/dashboard.svg";
import { SideBarLink, SidebarChildLink } from "../types/links";
import ProductSvg from "assets/svgs/product.svg";

type LinksObject = {
  [key: string]: string;
};
export const sidebarLinks: SideBarLink[] = [
    {
      title: "Dashboard",
      uri: "/",
      icon: "dashboard",
      permissions: [],
    },
    {
      title: "Product",
      uri: null,
      icon: "product",
      for: "/product",
      permissions: [3, 4, 5, 6, 7],
      children: [
        {
          title: "Product List",
          uri: "/product/products",
          permissions: [3, 4, 5],
        },
        {
          title: "Categories",
          uri: "/product/categories",
          permissions: [6],
        },
        {
          title: "Brands",
          uri: "/product/brands",
          permissions: [7],
        },
        {
          title: "Attributes",
          uri: "/product/attributes",
          permissions: [3],
        },
        {
          title: "Colors",
          uri: "/product/colors",
          permissions: [3],
        },
        {
          title: "Coupons",
          uri: "/product/coupons",
          permissions: [3],
        },
        {
          title: "Product Reviews",
          uri: "/product/products-reviews",
          permissions: [3, 16],
        },
      ],
    },
    {
      title: "Orders",
      uri: "/orders",
      icon: "orders",
      permissions: [11],
    },
    {
      title: "Customers",
      uri: "/customers",
      icon: "users",
      permissions: [9],
    },
    {
      title: "Vendors",
      uri: null,
      icon: "vendors",
      for: "/vendors",
      permissions: [8],
      children: [
        {
          title: "Sellers List",
          uri: "/vendors/list",
          permissions: [8],
        },
        {
          title: "Payout",
          uri: "/vendors/payout",
          permissions: [20],
        },
        {
          title: "Commission",
          uri: "/vendors/commission",
          permissions: [20],
        },
      ],
    },
    {
      title: "Ads & Broadcasts",
      uri: null,
      icon: "ads",
      for: "/marketing",
      permissions: [15, 16],
      children: [
        {
          title: "Banner Ads",
          uri: "/marketing/banners",
          permissions: [15],
        },
        {
          title: "Broadcast",
          uri: "/marketing/broadcast",
          permissions: [15],
        },
        {
          title: "Coupons",
          uri: "/marketing/coupons",
          permissions: [15],
        },
      ],
    },
    {
      title: "Blog",
      uri: null,
      icon: "blog",
      for: "/blog",
      permissions: [21, 22, 23],
      children: [
        {
          title: "Topics",
          uri: "/blog/topics",
          permissions: [23],
        },
        {
          title: "Blog List",
          uri: "/blog/list",
          permissions: [21, 22],
        },
      ],
    },
    {
      title: "Reports",
      uri: null,
      icon: "reports",
      for: "/reports",
      permissions: [16],
      children: [
        {
          title: "Products",
          uri: "/reports/products",
          permissions: [16],
        },
        {
          title: "Product Wishlist",
          uri: "/reports/product-wishlist",
          permissions: [16],
        },
        {
          title: "User Searches",
          uri: "/reports/user-searches",
          permissions: [16],
        },
      ],
    },
    {
      title: "Analytics",
      uri: "/analysis",
      icon: "analysis",
      permissions: [17],
    },
    {
      title: "Logistics Partners",
      uri: null,
      icon: "logistics",
      for: "/logistics",
      permissions: [10],
      children: [
        {
          title: "Logistics Overview",
          uri: "/logistics/overview",
          permissions: [10],
        },
        {
          title: "Logistics Partners",
          uri: "/logistics/partners",
          permissions: [10],
        },
      ],
    },
    {
      title: "Support",
      uri: "/support",
      icon: "support",
      permissions: [],
    },
    {
      title: "Staff Mgt",
      uri: null,
      icon: "staff",
      for: "/staff",
      permissions: [1, 2],
      children: [
        {
          title: "Staff List",
          uri: "/staff/list",
          permissions: [1, 2],
        },
        {
          title: "Emails",
          uri: "/staff/emails",
          permissions: [1],
        },
        {
          title: "Roles",
          uri: "/staff/roles",
          permissions: [2],
        },
        {
          title: "Permissions",
          uri: "/staff/permissions",
          permissions: [1],
        },
      ],
    },
    {
      title: "General Settings",
      uri: "/settings",
      icon: "settings",
      for: "/settings",
      permissions: [14],
      children: [
        {
          title: "Currency",
          uri: "/settings/currency",
          permissions: [14],
        },
        {
          title: "Shipping",
          uri: "/settings/shipping",
          permissions: [14],
        },
      ],
    },
  ],
  generateLinksObject = (links: SideBarLink[]): LinksObject => {
    const result: LinksObject = {};

    const addLinks = (
      links: (SideBarLink | SidebarChildLink)[],
      parentKey: string = ""
    ): void => {
      links.forEach((link) => {
        const key = parentKey
          ? `${parentKey}.${link.title.replace(/\s+/g, "").toLowerCase()}`
          : link.title.replace(/\s+/g, "").toLowerCase();
        if (link.uri) {
          result[key] = link.uri;
        }
        if ("children" in link && link.children) {
          addLinks(link.children, key);
        }
      });
    };

    addLinks(links);
    return result;
  },
  compileLinks = (
    links: (SideBarLink | SidebarChildLink)[],
    parentTitle: string = ""
  ): { title: string; uri: string }[] => {
    let compiledLinks: { title: string; uri: string }[] = [];

    for (const link of links) {
      const fullTitle = parentTitle
        ? `${parentTitle} > ${link.title}`
        : link.title;

      if (link.uri) {
        compiledLinks.push({
          title: fullTitle,
          uri: link.uri,
        });
      }

      if ("children" in link && link.children) {
        compiledLinks = compiledLinks.concat(
          compileLinks(link.children, fullTitle)
        );
      }
    }

    return compiledLinks;
  },
  searchSidebarLinks = (
    links: SideBarLink[],
    searchString: string
  ): { title: string; uri: string }[] => {
    const compiledLinks = compileLinks(links);

    return compiledLinks.filter((link) =>
      link.title.toLowerCase().includes(searchString.toLowerCase())
    );
  },
  links = {
    dashboard: "/",
    "product.productlist": "/product/products",
    "product.categories": "/product/categories",
    "product.brands": "/product/brands",
    orders: "/orders",
    blog: "/blog/list",
    customers: "/customers",
    "vendors.sellerslist": "/vendors/list",
    "vendors.payout": "/vendors/payout",
    "vendors.commission": "/vendors/commission",
    "marketing.bannerads": "/marketing/banners",
    "marketing.broadcast": "/marketing/broadcast",
    "marketing.coupons": "/marketing/coupons",
    "reports.products": "/reports/products",
    "reports.productwishlist": "/reports/product-wishlist",
    "reports.usersearches": "/reports/user-searches",
    analytics: "/analysis",
    "logisticspartners.logisticsoverview": "/logistics/overview",
    "logisticspartners.logisticspartners": "/logistics/partners",
    support: "/support",
    generalsettings: "/settings",
    "generalsettings.currency": "/settings/currency",
    "generalsettings.shipping": "/settings/shipping",
  },
  mainURL = "https://cusorcart.com",
  authLinks: {
    login: any;
    register: string;
    password_reset: string;
    forgot_password: string;
    verify_reset: string;
    verify: string;
  } = {
    login: "/auth/login",
    register: "/auth/register",
    forgot_password: "/auth/forgot-password",
    password_reset: "/auth/password/reset",
    verify_reset: "/auth/verify/reset",
    verify: "/auth/verify",
  };
export const allLinksToArray = Object.values(links);

export function filterSideBarLinks(
  userType: string,
  userPermissions: number[]
): SideBarLink[] {
  const links = sidebarLinks;
  if (userType === "admin") {
    return links;
  }

  return links
    .map((link) => {
      // Filter children based on permissions, or if permissions are empty
      const filteredChildren = link.children?.filter(
        (child) =>
          child.permissions.length === 0 ||
          child.permissions.some((permission) =>
            userPermissions.includes(permission)
          )
      );

      // Check if the main link requires no permission or has the required permissions
      const hasPermission =
        link.permissions.length === 0 || // No permissions required
        link.permissions.some((permission) =>
          userPermissions.includes(permission)
        );

      // Return the link if it has the required permissions, no permissions, or if any of its children do
      if (hasPermission || (filteredChildren && filteredChildren.length > 0)) {
        return {
          ...link,
          children: filteredChildren,
        };
      }

      return null;
    })
    .filter((link): link is NonNullable<typeof link> => link !== null); // Filter out null values
}
