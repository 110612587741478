"use client";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "store/user/user.selector";
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Skeleton,
  User,
} from "@nextui-org/react";
import { removeFromCookieProtected } from "utils/helper/helper";
import { signOutStart } from "store/user/user.action";
import { AUTH_TOKEN, sizes } from "utils/helper/states";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { User as UserData } from "utils/types/user";
import { Link } from "react-router-dom";

function HeaderUserDropdown() {
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(signOutStart());
    removeFromCookieProtected(AUTH_TOKEN);
  };
  const isLargeScreen = useMediaQuery(`(min-width: ${sizes.laptop}px)`);
  const userButton = (user: UserData) =>
    isLargeScreen ? (
      <User
        as="button"
        avatarProps={{
          isBordered: true,
          src: user.avatar,
        }}
        className="transition-transform"
        description={user.role != null ? user.role.name : user.type}
        name={user.name}
      />
    ) : (
      <Avatar
        as="button"
        isBordered
        src={user.avatar}
        className="transition-transform"
      />
    );
  return (
    <Dropdown placement="bottom-start">
      <DropdownTrigger>
        {user == null ? (
          <div className="max-w-[300px] w-full flex items-center gap-3">
            <div>
              <Skeleton className="flex rounded-full w-10 h-10" />
            </div>
          </div>
        ) : (
          userButton(user)
        )}
      </DropdownTrigger>
      <DropdownMenu
        aria-label="User Actions"
        variant="flat">
        <DropdownItem
          key="profile-details"
          className="h-14 gap-2">
          <p className="font-bold">Signed in as</p>
          <p className="font-bold">@{user?.name}</p>
        </DropdownItem>
        <DropdownItem
          as={Link}
          key="profile"
          href="/profile">
          Profile
        </DropdownItem>
        <DropdownItem
          key="logout"
          onPress={logout}
          color="danger">
          Log Out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default HeaderUserDropdown;
