import { Categories } from "utils/types/category";
import { createSlice } from "@reduxjs/toolkit";

export type CategoryState = {
  readonly all: Categories | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: CategoryState = {
  all: null,
  searchValue: "",
  loading: false,
};
const categorySlice = createSlice({
  name: "category",
  initialState: INITIAL_STATE,
  reducers: {
    setAllCategory(state, action) {
      state.all = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const categoryActions = categorySlice.actions;
export default categorySlice;
