"use client";

import { classNames } from "utils/helper/helper";
import { filterSideBarLinks } from "utils/helper/links";
import SidebarItem from "./sidebar-item.component";
import logoIcon from "assets/logo-icon.svg";
import MenuSearch from "./menu-search.component";
import { useSelector } from "react-redux";
import { selectSidebarSm } from "store/data/data.selector";
import LogoSvg from "assets/svgs/logo.svg";
import { selectCurrentUser } from "store/user/user.selector";
import { Link } from "react-router-dom";

function Sidebar() {
  const isSmall = useSelector(selectSidebarSm);
  const user = useSelector(selectCurrentUser);

  const sidebarLinks =
    user == null
      ? []
      : filterSideBarLinks(
          user.type,
          user.role != null ? user.role.permissions : []
        );
  return (
    <div
      className={classNames(
        "app-menu border-r dark:border-dark-border",
        "transition-all duration-75 ease-linear",

        "absolute left-0 top-0 z-[40] flex h-screen w-72.5 flex-col overflow-y-hidden",
        "bg-white duration-300 ease-linear lg:static lg:translate-x-0 -translate-x-full",

        "dark:bg-primary-950 group py-6",
        isSmall && "sidebar-size-sm"
      )}>
      <div className="flex items-center group-[.sidebar-size-sm]:justify-center px-5 py-6 text-center h-header">
        <Link
          to={"/"}
          className="flex items-center gap-2">
          <span className="hidden group-[.sidebar-size-sm]:block">
            <img
              src={logoIcon}
              alt=""
              className="h-6 w-6 mx-auto"
            />
          </span>
          <span className="group-[.sidebar-size-sm]:hidden">
            <LogoSvg className="h-6 w-auto mx-auto dark:text-white text-primary" />
          </span>
        </Link>
        <button
          type="button"
          className="hidden p-0 float-end"
          id="vertical-hover">
          <i className="ri-record-circle-line" />
        </button>
      </div>
      <div
        className={classNames(
          "px-3 mb-6",
          "group-[.sidebar-size-sm]:flex group-[.sidebar-size-sm]:justify-center"
        )}>
        <MenuSearch />
      </div>
      <div
        id="scrollbar"
        className="mx-auto hover:scrollbar-show overflow-y-auto h-full max-h-full">
        <div className="simplebar-wrapper group-[.sidebar-size-sm]:w-16 w-64">
          <ul className="menu w-full gap-2 font-semibold">
            {sidebarLinks.map((link, i) => (
              <SidebarItem
                item={link}
                key={i}
              />
            ))}
          </ul>
        </div>
        {/* Sidebar */}
      </div>
    </div>
  );
}

export default Sidebar;
